// @import Dependencies
import * as Yup from 'yup';
import i18next from 'i18next';

export const formPage = 'login';

export const formFields = { email: '', password: '' };

export const formValidation = Yup.object().shape({
  email: Yup.string()
    .email(i18next.t(`${formPage}.form.fields.email.notValid`))
    .required(i18next.t(`${formPage}.form.fields.email.required`)),
  password: Yup.string().required(
    i18next.t(`${formPage}.form.fields.password.required`)
  ),
});
