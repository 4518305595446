import lightMode from '@theme/modes/light';
import darkMode from '@theme/modes/dark';
import goldMode from '@theme/modes/gold';

const palette = {
  light: lightMode,
  dark: darkMode,
  gold: goldMode,
};

export default palette;
