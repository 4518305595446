const rootElement = document.getElementById('root');

export default function Popover() {
  return {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
      variants: [
        {
          props: { variant: 'filter' },
          style: {
            '.MuiPopover-paper': {
              padding: 30,
              borderRadius: 20,
            },
          },
        },
        {
          props: { variant: 'dashboard_selector' },
          style: {
            '.MuiPopover-paper': {
              padding: 10,
              borderRadius: 10,
            },
          },
        },
      ],
    },
  };
}
