import button from '@theme/overrides/Button';
import chip from '@theme/overrides/Chip';
import menu from '@theme/overrides/Menu';
import inputBase from '@theme/overrides/InputBase';
import iconButton from '@theme/overrides/IconButton';
import dialog from '@theme/overrides/Dialog';
import slider from '@theme/overrides/Slider';
import card from '@theme/overrides/Card';
import linearProgress from '@theme/overrides/LinearProgress';
import popover from '@theme/overrides/Popover';
import switchMui from '@theme/overrides/Switch';
import tableCell from '@theme/overrides/TableCell';
import popper from '@theme/overrides/Popper';
import skeleton from '@theme/overrides/Skeleton';

export default function componentsOverrides(theme) {
  return Object.assign(
    button(theme),
    chip(theme),
    menu(theme),
    inputBase(theme),
    iconButton(theme),
    dialog(theme),
    slider(theme),
    card(theme),
    linearProgress(theme),
    popover(theme),
    switchMui(theme),
    tableCell(theme),
    popper(theme),
    skeleton(theme)
  );
}
