import { useSelector } from 'react-redux';
import { selectUserData } from '@redux/slices/user';

const useHasPermission = (priority) => {
  const { user } = useSelector(selectUserData);

  let titleKey = priority;

  const permissions = {
    team: user?.seat_used < user?.seat_limits,
    hasPDP: true,
    planName: Boolean(user?.plan_name),
    isEmailVerified: Boolean(user?.is_email_verified),
  };

  if (Array.isArray(priority)) {
    for (const permission of priority) {
      titleKey = permission;
      if (!permissions[permission]) break;
    }
  }

  return { titleKey, permissions };
};

export default useHasPermission;
