import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUIMode, updateTheme } from '@redux/slices/user';
import { guestPaths, matchPathMode } from '@routes/paths';

const initialState = {
  themeMode: 'default',
  onChangeMode: () => {},
};

const ThemeModeContext = createContext(initialState);

ThemeModeProvider.propTypes = {
  children: PropTypes.node,
};

function ThemeModeProvider({ children }) {
  const dispatch = useDispatch();
  const existsSession = localStorage.getItem('accessToken');
  const location = window.location.pathname;
  const uiMode = useSelector(selectUIMode);
  const pathModeByDefault = matchPathMode(location);
  const stateThemeMode =
    pathModeByDefault && !existsSession
      ? pathModeByDefault
      : uiMode || 'default';

  const validThemes = ['light', 'gold'];

  const invalidateOldTheme = (oldTheme) => {
    if (!oldTheme || !validThemes.includes(oldTheme)) return 'light';
    return oldTheme;
  };

  const [themeMode, setThemeMode] = useState(
    invalidateOldTheme(
      stateThemeMode === 'default'
        ? localStorage.getItem('theme') ||
            (window.matchMedia('(prefers-color-scheme: dark)').matches
              ? 'gold'
              : 'light')
        : stateThemeMode
    )
  );

  useEffect(() => {
    if (uiMode) setThemeMode(invalidateOldTheme(uiMode));
  }, [uiMode]);

  const onChangeMode = (value) => {
    if (value) {
      localStorage.setItem('theme', value);
      setThemeMode(value);
      if (
        Object.values(guestPaths).some((path) => path.includes(location)) ||
        !existsSession
      ) {
        return;
      }
      const themeFormData = {
        theme: { ui_mode: value },
        headers: { 'Content-Type': 'application/json' },
      };
      dispatch(updateTheme(themeFormData));
    }
  };

  return (
    <ThemeModeContext.Provider
      value={{
        themeMode,
        onChangeMode,
      }}
    >
      {children}
    </ThemeModeContext.Provider>
  );
}

export { ThemeModeProvider, ThemeModeContext };
