const rootElement = document.getElementById('root');

export default function Popper() {
  return {
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  };
}
