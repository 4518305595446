import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserData } from '@redux/slices/user';
import { isAdminUser } from '@utils/validations';

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default function AdminGuard({ children }) {
  const { user } = useSelector(selectUserData);
  const isAdmin = isAdminUser(user);
  if (!isAdmin) {
    return <Navigate to={'/app/account/settings'} />;
  }

  return <>{children}</>;
}
