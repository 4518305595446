import { GREY, COMMON, PRIMARY } from '@theme/modes/common';
import { DARK } from '@theme/shadows';

const styles = {
  ...COMMON,
  text: {
    primary: '#ffffff',
    secondary: '#ffffff',
    disabled: GREY[600],
    neutral: GREY[800],
    alternative: '#CCA377',
    contrastText: '#000',
    alternativeContrastText: '#000',
    link: '#ffffff',
    customTitle: '#ffffff',
  },
  disableButtonText: 'rgba(131, 131, 131, 1)',
  sidebar: {
    background: '#1F2D32',
    border: 'transparent',
    borderDivider: '#2D4955',
    icon: {
      default: '#ffffff',
      active: '#CBA376',
      background: '#2C3B42',
      backgroundActive: '#CBA37626',
    },
  },
  optimizationLog: {
    rowRevertion:
      'linear-gradient(135deg, rgba(203, 163, 118, 0.15) 0%, #65513B 100%)',
  },
  auth: {
    input: '#141414',
  },
  datePicker: {
    border: '#2D4955',
  },
  background: {
    paper: '#131C1E',
    default: '#131C1E',
    neutral: GREY[500_16],
  },
  table: {
    background: {
      default: '#2C3B42',
      header: '#1F2C32',
    },
    border: '#2D4955',
    primary: '#131C1E',
    stickyHeader: '#1F2D32',
    alternative: '#CCA377',
  },
  shadows: DARK,
  action: { active: GREY[500], ...COMMON.action },
  cards: {
    backgrounds: {
      primary: '#2C3B42',
      secondary: '#1F2C32',
    },
    shadows: {
      primary: 'transparent',
      secondary: 'transparent',
    },
    border: 'transparent',
    skeletonBorder: '#2C3B42',
    planRailBackground: '#EEEEEE',
    subscriptionBackground: '#1F2D32',
    cardPrice: '#CCA377',
    checkGradient: {
      from: '#CCA377',
      to: '#CCA377',
    },
    integrationBackground: '#2C3B42',
    integrationText: '#fff',
    settings: {
      background: '#1F2D32',
      shadow: 'none',
    },
    members: {
      background: '#1F2D32',
      shadow: 'none',
    },
    optimize: {
      notPushed: '#942430',
      pushed: '#CBA376',
    },
    assetsBackground: '#1F2D32',
    plan: {
      essential: {
        background: '#1f2d32',
        text: '#FDFAF7',
        border: GREY[400],
      },
      growth: {
        background: '#1f2d32',
        text: '#FDFAF7',
        border: GREY[400],
      },
      enterprise: {
        background: '#FDFAF7',
        text: GREY[800],
        border: GREY[400],
      },
    },
  },
  switch: {
    active: '#CCA377',
    inactive: '#CBA37626',
    theme: {
      text: '#CCA377',
      checked: GREY[0],
      trackBackground: '#CCA377',
      background: '#1F2D32',
    },
  },
  dropdowns: {
    secondary: '#2a1c22',
    border: '#2D4955',
    darker: '#1F2D32',
    text: '#fff',
    indicatorColor: '#fff',
    parentContainer: 'transparent',
    childContainer: '#1F2D32',
    singleOptionBackground: '#1F2D32',
    multiOptionBackground: '#1F2D32',
    checkBox: '#CCA377',
    member: {
      backgroundSelector: '#fff',
      backgroundOptions: '#fff',
      border: '#1F2D32',
    },
    storeVariant: {
      text: '#fff',
      backgroundIconHover: '#2C3B42',
    },
    defaultIconBackground: '#962330',
  },
  loader: {
    spinner: {
      border: PRIMARY['main'],
      shadow: PRIMARY['dark'],
      shadowContrast: '#CBA376',
      background: '#00000099',
    },
  },
  various: {
    buttonOptions: '#FF4E8D',
    matchText: '#CBA376',
    newText: '#68B0B0',
    dashboardsCardsText: '#fff',
    softIcons: '#FFF',
    divider: '#FFF',
    errorPlaceholder: '#FF3B3B',
    errorMessages: '#FF2424',
    badgeCounter: '#D42C9B',
    emailButton: '#2C3B42',
  },
  icon: {
    primary: '#CBA376',
  },
  dashboard: {
    activityLog: {
      borderDivider: '#CBA376',
      itemBackground: '#2C3B42',
    },
    graphic: {
      subtitle: '#fff',
      containerBg: '#191919',
      gridColor: '#CBA37626',
      optimized: '#943432',
      nonOptimized: '#434C4C',
      sessions: '#C9412633',
      conversions: '#888888',
      filter: {
        optionSelected: '#CBA376',
        option: '#191919',
      },
    },
    navbar: {
      stickyBackground: '#1F2D32',
    },
  },
  keywords: {
    input: '#2C3B42',
    button: '#8D8495',
    buttonModal: '#4D3974',
  },
  checkBox: {
    border: '#C5CBD3',
    background: '#CBA376',
    icon: '#000',
  },
  buttons: {
    background: '#CBA376',
    text: '#000',
    backgroundSecondary: '#2D4955',
    border: '#2D4955',
    borderSecondary: '#000',
  },
  logoutButton: {
    background: COMMON.common.white,
    text: '#000',
  },
  GSCButton: {
    backgroundCard: '#2C3B42',
    backgroundButton: '#3f3037',
  },
  stepper: {
    disabled: '#ECECEC',
    dividerBorder: GREY[300],
  },
  permissionsDenied: {
    gradient: {
      from: '#942430',
      to: '#CBA376',
    },
    denied: '#C64865',
  },
  successsView: {
    borderContainer: '#1F2D32',
    iconBorder: '#cbd1d8',
    backgroundContainer: '#1F2D32',
  },
  cmsIntregrations: {
    backgroundLogo: 'transparent',
    borderLogo: '#fff',
    borderSelectedLogo: '#AEA6C4',
    backgroundSelectedLogo: '#8C83C333',
  },
  dialog: {
    background: '#c5cbd380',
    backgroundContainer: '#1F2D32',
    resultBg: '#1F2D32',
  },
  metrics: {
    card: {
      background: { default: '#1F2C32', main: '#2C3B42', secondary: '#1F2C32' },
    },
  },
  snackBar: {
    background: {
      success: PRIMARY['darker'],
      error: PRIMARY['darker'],
    },
  },
  subscription: {
    alternative: '#CBA376',
  },
  brandGuideline: {
    background: '#2C3B42',
  },
  slider: {
    background: '#CBA376',
    border: '#942430',
  },
  textEditor: {
    outputBackground: '#2C3B42',
    delimiter: 'CBA376',
    attribute: '68B0B0',
    attributeValue: 'EC7B7B',
  },
  cmsIcons: {
    ...COMMON.cmsIcons,
    woocommerce: {
      ...COMMON.cmsIcons.woocommerce,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#1F2D32',
      },
      cardVersion: {
        stroke: 'none',
        svgFill: '#fff',
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: '#000',
      },
    },
    shopify: {
      ...COMMON.cmsIcons.shopify,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#1F2D32',
      },
      cardVersion: {
        stroke: '#fff',
        svgFill: '#1F2D32',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    magento: {
      ...COMMON.cmsIcons.magento,
      cardVersion: {
        stroke: '#fff',
        svgFill: '#1F2D32',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    akeneo: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    bigcommerce: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        stroke: '#000',
      },
    },
    webflow: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        svgFill: '#000',
      },
    },
  },
  moderationCard: {
    iconBackground: '#1F2D32',
    containerBackground: '#1F2D32',
    iconContainerBackground: '#000',
    status: {
      success: '#3DC292',
      error: '#FF4F4F',
      warning: '#FF994F',
    },
  },
  onboarding: {
    containerBg: '#1F2D32',
    automaticCardBg: '#2C3B42',
    cardBg: '#2C3B42',
    cardBorder: '#fff',
    progressBg: '#CBA376',
    progressTransparency: '#494949',
    buttonBorder: '#CBA376',
    shopsContainerBg: '#2C3B42',
    buttonText: '#000',
    rulesContainerBg: '#2C3B42',
    rulesContainerSettingsBg: '#2C3B42',
    buttonDisabled: '#CBA37626',
    disabledCardBg: '#2C3B42',
    disabledCardBorder: '#fff',
    disabledCardText: '#fff',
    gscCardBg: '#2C3B42',
    rulesBg: '#2C3B42',
    mostPopular: '#1f2d32b3',
  },
  tinymce: {
    background: '#1F2D32',
    backgroundHeader: '#2C3B42',
    html: '#CBA376',
    variables: '#68B0B0',
    values: '#EC7B7B',
  },
  feedback: {
    background: '#1F2D32',
    inputBackground: '#2C3B42',
  },
  skeleton: {
    background: '#3b4e57',
  },
};
export default styles;
