import PropTypes from 'prop-types';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData, setUserDetails } from '@redux/slices/user';
import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { formPage } from '@pages/auth/login/utils';
import { confirmEmail } from '@redux/slices/auth';
import useHasPermission from '@hooks/useHasPermission';
import { toggleAccessDeniedModal } from '@redux/slices/navigation';
import { authPaths } from '@routes/paths';

EmailVerificationGuard.propTypes = {
  children: PropTypes.node,
};

export default function EmailVerificationGuard({ children }) {
  const dispatch = useDispatch();
  const { user, loading } = useSelector(selectUserData);
  const { pathname } = useLocation();
  const loadingVerify = useRef(true);
  const { titleKey, permissions } = useHasPermission('isEmailVerified');
  const [searchParams] = useSearchParams();
  const environment = import.meta.env.VITE_ENV;
  const avoidRoutes = [
    authPaths.accountSettings,
    authPaths.onboarding,
    authPaths.billingRedirection,
    authPaths.signUpPlan,
    authPaths.success,
  ];

  useEffect(() => {
    handleVerification();
  }, [pathname, loading, permissions]);

  const validateConfirmEmail = async () => {
    const verifyToken = searchParams.get('verifyEmail');
    if (verifyToken && loadingVerify.current) {
      try {
        const confirmData = await dispatch(confirmEmail(verifyToken)).unwrap();
        if (confirmData?.isSuccessful) {
          dispatch(
            setUserDetails({
              user: {
                is_email_verified: confirmData.is_email_verified,
              },
            })
          );
          dispatch(toggleAccessDeniedModal({ isVisible: false }));
          if (environment === 'production') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'email_confirmation',
                email_confirmed: confirmData?.email,
              },
            });
          }
        }
        loadingVerify.current = false;
      } catch {
      } finally {
        window.history.replaceState(null, '', `/${formPage}`);
      }
    }
  };

  const handleVerification = () => {
    validateConfirmEmail();
    if (
      user?.id &&
      !loading &&
      !permissions.isEmailVerified &&
      !avoidRoutes.includes(pathname)
    )
      dispatch(toggleAccessDeniedModal({ isVisible: true, titleKey }));
  };

  return children;
}
