// @import Dependencies
import i18next from 'i18next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// @import Services
import {
  changePasswordRequest,
  confirmEmail as confirmEmailAPI,
  forgotPassword as forgotPasswordAPI,
  google as googleAPI,
  login as loginAPI,
  logout as logoutAPI,
  register as registerAPI,
  setNewPassword as setNewPasswordAPI,
  validateInvitationCodeAPI,
  verifyEmail as verifyEmailAPI,
} from '@api/auth';

// @import Reducers
import {
  getAuthUserDetails,
  removeTeamMemberFromState,
  setUserDetails,
  updateMember,
} from '@redux/slices/user';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';

// @import translation
import {
  addTeamMemberRequest,
  registerTeamMemberRequest,
  removeTeamMemberRequest,
  updateTeamMemberRequest,
} from '@api/teamMember';

export const initialState = {
  showForm: true,
  register: {
    loading: false,
    token: null,
  },
  registerTeamMember: {
    loading: false,
    token: null,
  },
  login: {
    loading: false,
  },
  logout: {
    loading: false,
  },
  forgotPassword: {
    email: false,
    error: false,
    loading: false,
  },
  setNewPassword: {
    error: true,
    loading: false,
  },
  verifyEmail: {
    email: null,
    loading: false,
    sentSinceRegister: false,
    token: null,
  },
};

export const register = createAsyncThunk(
  'auth/register',
  async (userData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await registerAPI(userData);
    if (isSuccessful) {
      localStorage.setItem('accessToken', data.token);
      const isEmailVerified = data?.user?.is_email_verified;
      if (data.token) thunkAPI.dispatch(setUserDetails({ ...data }));
      return { ...userData, ...data, pendingVerify: !isEmailVerified };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const sendVerifyEmail = createAsyncThunk(
  'auth/email-verification',
  async (emailData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await verifyEmailAPI();
    if (isSuccessful) return { ...emailData, ...data, statusKey };
    else return thunkAPI.rejectWithValue({ ...emailData, data, statusKey });
  }
);

export const confirmEmail = createAsyncThunk(
  'auth/verify-email',
  async (token, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await confirmEmailAPI(token);
    if (isSuccessful) return { ...data, statusKey, isSuccessful };
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ credentials, extraAttributes }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await loginAPI(credentials);
    if (isSuccessful) {
      if (!extraAttributes?.notLogin) {
        localStorage.setItem('accessToken', data.token);
        thunkAPI.dispatch(getAuthUserDetails(data));
      }
      return isSuccessful;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await logoutAPI(params);
    if (isSuccessful) return { ...data, ...params };
    else return thunkAPI.rejectWithValue({ ...data, ...params, statusKey });
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (forgotData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await forgotPasswordAPI(
      forgotData
    );
    if (isSuccessful) return { ...forgotData, ...data };
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const setNewPassword = createAsyncThunk(
  'auth/setNewPassword',
  async (passwordData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await setNewPasswordAPI(
      passwordData,
      passwordData.token
    );
    if (isSuccessful) return data;
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await changePasswordRequest(body);
    if (isSuccessful) return { ...data, statusKey };
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const google = createAsyncThunk(
  'auth/google',
  async ({ token, credential }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await googleAPI({
      credential,
      organization_token: token,
    });
    if (isSuccessful) {
      localStorage.setItem('accessToken', data.token);
      thunkAPI.dispatch(getAuthUserDetails(data));
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const registerTeamMember = createAsyncThunk(
  'auth/registerTeamMember',
  async (userData, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await registerTeamMemberRequest(
      userData
    );
    if (isSuccessful) {
      localStorage.setItem('accessToken', data.token);
      thunkAPI.dispatch(getAuthUserDetails({ ...data, is_first_time: false }));
      return { ...data, statusKey };
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const addTeamMember = createAsyncThunk(
  'auth/addTeamMember',
  async (email, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await addTeamMemberRequest(email);
    if (isSuccessful) return { statusKey };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const removeTeamMember = createAsyncThunk(
  'auth/removeTeamMember',
  async (email, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await removeTeamMemberRequest(
      email
    );
    if (isSuccessful) {
      thunkAPI.dispatch(removeTeamMemberFromState({ email, statusKey }));
      return { ...data, statusKey };
    }

    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateTeamMember = createAsyncThunk(
  'auth/updateTeamMember',
  async ({ id, body }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateTeamMemberRequest(
      id,
      body
    );
    if (isSuccessful) {
      thunkAPI.dispatch(updateMember({ ...data }));
      return { ...data, statusKey };
    }

    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const authWithToken = createAsyncThunk(
  'auth/authWithToken',
  async (token, thunkAPI) => {
    localStorage.setItem('accessToken', token);
    const authData = await thunkAPI
      .dispatch(
        getAuthUserDetails({
          token,
          extraAttributes: { noAuthReload: true, showSnackError: true },
        })
      )
      .unwrap();

    if (authData) return { ...authData };
    return thunkAPI.rejectWithValue({ authData });
  }
);

export const validateInvitationCode = createAsyncThunk(
  'auth/validateInvitationCode',
  async (code, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await validateInvitationCodeAPI(
      code
    );
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder
    // Register
    .addCase(register.pending, (state) => {
      state.register.loading = true;
    })
    .addCase(register.fulfilled, (state, action) => {
      state.register = {
        ...state.register,
        token: action.payload.token,
        loading: !action.payload?.pendingVerify,
      };
      if (action.payload?.pendingVerify) {
        state.verifyEmail = {
          email: action.payload.email,
          sentSinceRegister: true,
          loading: false,
        };
        nomenclatureSnack({
          type: 'success',
          message: i18next.t('verifyEmail.toast'),
        });
      }
    })
    .addCase(register.rejected, (state, action) => {
      state.register.loading = false;
      localStorage.clear();
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
        snackCustomConfig: {
          autoHideDuration: null,
          messageLinks: {
            'login here': '/login',
          },
        },
      });
    })
    // Send Verify Email
    .addCase(sendVerifyEmail.pending, (state, action) => {
      const data = action.payload;
      if (validateOrigin(data?.origin, 'register'))
        state.register.loading = true;
      state.verifyEmail.loading = true;
    })
    .addCase(sendVerifyEmail.fulfilled, (state, action) => {
      const data = action.payload;
      if (validateOrigin(data?.origin, 'register'))
        state.register.loading = false;

      state.verifyEmail = {
        ...state.verifyEmail,
        sentSinceRegister: validateOrigin(data?.origin, 'register'),
        loading: false,
      };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(sendVerifyEmail.rejected, (state, action) => {
      const data = action.payload;
      if (validateOrigin(data?.origin, 'register'))
        state.register.loading = false;
      state.verifyEmail.loading = false;
      localStorage.clear();
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Confirm Email
    .addCase(confirmEmail.pending, (state) => {
      state.verifyEmail.loading = true;
    })
    .addCase(confirmEmail.fulfilled, (state, action) => {
      state.verifyEmail.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
        extraParams: { email: '' },
      });
    })
    .addCase(confirmEmail.rejected, (state, action) => {
      state.verifyEmail.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Login
    .addCase(login.pending, (state) => {
      state.login.loading = true;
    })
    .addCase(login.fulfilled, () => {})
    .addCase(login.rejected, (state, action) => {
      state.login.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Logout
    .addCase(logout.pending, (state) => {
      state.logout.loading = true;
    })
    .addCase(logout.fulfilled, (state, action) => {
      localStorage.clear();
      state.logout.loading = false;
      if (!action?.payload?.notReload) window.location.reload();
    })
    .addCase(logout.rejected, (state, action) => {
      localStorage.clear();
      state.logout.loading = false;
      if (!action?.payload?.notReload) window.location.reload();
    })
    // Forgot password
    .addCase(forgotPassword.pending, (state) => {
      state.forgotPassword.loading = true;
    })
    .addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPassword = {
        loading: false,
        error: false,
        email: action.payload?.email,
      };
    })
    .addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = true;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Set New password
    .addCase(setNewPassword.pending, (state) => {
      state.setNewPassword.loading = true;
    })
    .addCase(setNewPassword.fulfilled, (state) => {
      state.setNewPassword = {
        loading: false,
        error: false,
      };
    })
    .addCase(setNewPassword.rejected, (state, action) => {
      state.setNewPassword = {
        loading: false,
        error: true,
      };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Change password
    .addCase(changePassword.pending, (state) => {
      state.loading = true;
    })
    .addCase(changePassword.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    })
    // Google
    .addCase(google.pending, (state) => {
      state.login.loading = true;
      state.register.loading = true;
    })
    .addCase(google.fulfilled, () => {})
    .addCase(google.rejected, (state, action) => {
      state.login.loading = false;
      state.register.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Register Team Member
    .addCase(registerTeamMember.pending, (state) => {
      state.registerTeamMember.loading = true;
    })
    .addCase(registerTeamMember.fulfilled, (state, action) => {
      state.registerTeamMember.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(registerTeamMember.rejected, (state, action) => {
      state.registerTeamMember.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Add Team Member
    .addCase(addTeamMember.pending, () => {})
    .addCase(addTeamMember.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(addTeamMember.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Remove Team Member
    .addCase(removeTeamMember.pending, () => {})
    .addCase(removeTeamMember.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(removeTeamMember.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateTeamMember.pending, (state) => {
      state.registerTeamMember.loading = true;
    })
    .addCase(updateTeamMember.fulfilled, (state, action) => {
      state.registerTeamMember.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateTeamMember.rejected, (state, action) => {
      state.registerTeamMember.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    // Login With Token
    .addCase(authWithToken.pending, (state) => {
      state.login.loading = true;
    })
    .addCase(authWithToken.fulfilled, (state) => {
      state.login.loading = false;
    })
    .addCase(authWithToken.rejected, (state) => {
      state.login.loading = false;
      localStorage.clear();
    })
    .addCase(validateInvitationCode.pending, (state) => {
      state.login.loading = true;
    })
    .addCase(validateInvitationCode.fulfilled, (state) => {
      state.login.loading = false;
    })
    .addCase(validateInvitationCode.rejected, (state, action) => {
      state.login.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    });
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    resetInit: () => {
      return initialState;
    },
    setShowForm: (state, action) => {
      state.showForm = action.payload;
    },
    resetRegisterVerifyEmail: (state, action) => {
      state.verifyEmail.sentSinceRegister = action.payload;
    },
    setVerifyEmail: (state, action) => {
      state.verifyEmail = { ...state.verifyEmail, ...action.payload };
    },
    setLoginLoading: (state, action) => {
      state.login.loading = action.payload;
    },
  },
});

const validateOrigin = (origin, matchOrigin) => {
  return origin === matchOrigin;
};
// ------------------EXPORT REDUCERS-------------
export const {
  resetInit,
  setShowForm,
  resetRegisterVerifyEmail,
  setVerifyEmail,
  setLoginLoading,
} = authSlice.actions;
export default authSlice.reducer;

// ------------------SELECTORS-------------
export const selectAuth = (state) => state.auth;
