export const list = [
  'Outlined1',
  'Outlined2',
  'Outlined3',
  'Outlined4',
  'Outlined5',
  'Outlined6',
  'Outlined7',
  'Outlined8',
  'Outlined9',
  'Outlined10',
  'Outlined11',
  'Outlined12',
  'Outlined13',
  'Outlined14',
  'Outlined15',
  'Outlined16',
  'Outlined17',
  'Outlined18',
  'Outlined19',
  'Outlined20',
  'Outlined21',
  'Outlined22',
  'Outlined23',
  'Outlined24',
  'Outlined25',
  'Outlined26',
  'Outlined27',
  'Outlined28',
  'Outlined29',
  'Outlined30',
  'Outlined31',
  'Outlined32',
  'Outlined33',
  'Outlined34',
  'Outlined35',
  'Outlined36',
  'Outlined37',
  'Outlined38',
  'Outlined39',
  'Outlined40',
  'Outlined41',
  'Outlined42',
  'Outlined43',
  'Outlined44',
  'Outlined45',
  'Outlined46',
  'Outlined47',
  'Outlined48',
  'Outlined49',
  'Outlined50',
  'Outlined51',
  'Outlined52',
  'Outlined53',
  'Outlined54',
  'Outlined55',
  'Outlined56',
  'Outlined57',
  'Outlined58',
  'Outlined59',
  'Outlined60',
  'Outlined61',
  'Outlined62',
  'Outlined63',
  'Outlined64',
  'Outlined65',
  'Outlined66',
  'Outlined67',
  'Outlined68',
  'EkomAutomationsCycle',
  'Filled1',
  'Filled2',
  'Filled3',
  'Filled4',
  'Filled5',
  'Filled6',
  'Filled7',
  'Filled8',
  'Filled9',
  'Filled10',
  'Filled11',
  'Filled12',
  'Filled13',
  'Filled14',
  'Filled15',
  'Filled16',
  'Filled17',
  'Filled18',
  'Filled19',
  'Filled20',
  'Filled21',
  'Filled22',
  'Filled23',
  'Filled24',
  'Filled25',
  'Filled26',
  'Filled27',
  'Filled28',
  'Filled29',
  'Filled30',
  'Filled31',
  'Filled32',
  'Filled33',
  'Filled34',
  'Filled35',
  'Filled36',
  'Filled37',
  'Filled38',
  'Filled39',
  'Filled40',
  'Filled41',
  'Filled42',
  'Filled43',
  'Filled44',
  'Filled45',
  'Filled46',
  'Filled47',
  'Filled48',
  'Filled49',
  'Filled50',
  'Filled51',
  'Filled52',
  'Filled53',
  'Filled54',
  'Filled55',
  'Filled56',
  'Filled57',
  'Filled58',
  'Filled59',
  'Filled60',
  'Filled61',
  'Filled62',
  'Filled63',
  'Filled64',
  'WriterlyLeftArrow',
  'WriterlyPen',
  'EkomShopifyMinified',
  'EkomMagento',
  'EkomMagentoMinified',
  'EkomOptimizedJob',
  'EkomManualOptimizeJob',
  'WriterlyLogout',
  'WriterlyMobileMenu',
  'WriterlyOpenEyeIcon',
  'WriterlyClosedEyeIcon',
  'EkomExternalPage',
  'EkomCMSCheck',
  'EkomWoocommerce',
  'EkomAkeneo',
  'EkomAkeneoMinified',
  'EkomBigcommerce',
  'EkomBigcommerceMinified',
  'EkomWebflow',
  'EkomWebflowMinified',
  'EkomSalesforce',
  'EkomFire',
  'EkomMagnolia',
  'EkomDashboard',
  'Bookmark',
  'BoldCircle',
  'PlusCircle',
  'MinusCircle',
  'EkomCheckRingInProgress',
  'EkomCheckRingFinished',
  'AutopilotJobNeedsReview',
  'ScheduleACall',
  'RightArrowOutlined',
  'EkomBars1',
  'EkomBars2',
  'EkomBars3',
  'EkomPeople1',
  'EkomPeople2',
  'EkomPeople3',
  'EkomSwords',
  'EkomShopify',
  'ReportsBook',
  'GradientCheck',
  'HasPDP',
  'Hourglass',
  'RoundedArrow',
  'StoresPC',
  'StorePC',
  'Store',
  'StoreArrow',
  'OptimizationSettings',
  'CreateAssets',
  'CustomerSupport',
  'AssetJob',
  'KeywordLegendBox',
  'KeywordLegendRemovedText',
  'RoundedCheck',
  'UploadFile',
  'OptimizeHistorical',
  'ExclamationMark',
  'InfoWrap',
  'AddNewUser',
  'GoogleConnected',
  'Ray',
  'Undo',
  'UpdatedProduct',
  'Star',
  'Locked',
  'RoundedWarningTriangle',
  'ShareBox',
  'MagicStars',
  'ConfigWheel',
  'CsvFile',
  'Logo',
  'RoundedLeftChevron',
  'ActivityLog',
  'OptimizedLegendGraph',
  'UnoptimizedLegendGraph',
  'PerformanceBars',
  'Crown',
  'EkomOptimizedLog',
  'GoogleAnalytics',
  'LogoRaw',
  'SearchData',
];
