export const camalize = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const validateEmail = (email) => {
  return email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi);
};

export const validateConfirmPassword = (password, confirmPassword) => {
  return password !== confirmPassword;
};

export const calculateStandardDeviation = (data) => {
  const average = data.reduce((a, b) => a + b, 0) / data.length;
  const summation = data.reduce((a, b) => a + Math.pow(b - average, 2), 0);
  const variance = summation / data.length;
  const deviation = Math.sqrt(variance);
  return deviation;
};

export const capitalizeWord = (str) =>
  str?.replace(
    /^(.)(.*)$/,
    (_, first, rest) => first.toUpperCase() + rest.toLowerCase()
  );

export const checkIfIntegraionsWasAdded = ({
  newIntegrations,
  oldIntegrations,
  integrationType,
}) => {
  let added = false;
  const filtered = newIntegrations.filter(
    (_i) => _i?.integration_type === integrationType
  );
  for (const integration of filtered) {
    const alreadyExist = oldIntegrations.find(
      (_i) => _i?.id === integration?.id
    );
    if (!alreadyExist) {
      added = true;
      break;
    }
  }
  return added;
};

export const isAdminUser = (user) => {
  return user?.role === 'admin' || user?.review_admin;
};
