// @import Dependencies
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import i18next from 'i18next';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

export const getTimeAgo = (dateStr) => {
  if (!dateStr) {
    return '';
  }

  const dateObj = dayjs(dateStr);

  return dateObj.fromNow();
};

export const formatUtcDate = (date) => {
  const dateObject = dayjs(date).utc();
  const formattedDate = dateObject.format('MM/DD/YYYY h:mma') + ' UTC';
  return formattedDate;
};

export const filterDataFromDates = ({ data, dateKey, filterCriteria }) => {
  if (filterCriteria === 'all') {
    return data;
  }
  const filterDate = dayjs().subtract(filterCriteria, 'day');

  const filteredData = data.filter((item) =>
    dayjs(item?.[dateKey]).isAfter(filterDate)
  );
  return filteredData;
};

export const getTimeAgoInSeconds = (seconds) => {
  if (!seconds) return '';
  const priorities = [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
  ];
  const duration = dayjs.duration(seconds, 'seconds');
  let formatted = '';
  for (const priority of priorities) {
    const value = duration['$d'][priority];
    if (value > 0) {
      const langKey = value > 1 ? 'plural' : 'singular';
      const unit = i18next.t(`timeUnits.${langKey}.${priority}`);
      formatted = `${value} ${unit}`;
      break;
    }
  }
  return formatted;
};

export const formatDateByYearAndMonth = ({ dateYear, dateMonth }) => {
  const date = dayjs(`${dateYear}-${dateMonth}`);
  return date.format('MMMM YYYY');
};

export const formatDateByMonthAndDay = (dateString, format = 'MM/DD') => {
  return dayjs(dateString).format(format);
};

export const getAverageTimeAgo = (dates) => {
  if (dates.some((dateStr) => !dateStr)) {
    return '';
  }
  // Step 1: Convert date strings to Day.js objects
  const dateObjects = dates.map((dateStr) => dayjs(dateStr));
  // Step 2: Calculate differences in years, months, weeks, and days between each date and the current date
  const currentDate = dayjs();
  const differences = dateObjects.map((dateObj) => currentDate.diff(dateObj));

  // Step 3: Calculate the average of the differences
  const averageDifference =
    differences.reduce((total, difference) => total + difference, 0) /
    differences.length;

  // Step 4: Convert the average difference to years, months, weeks, and days
  const years = Math.floor(averageDifference / (365 * 24 * 60 * 60 * 1000));
  const remainingDifference =
    averageDifference - years * (365 * 24 * 60 * 60 * 1000);
  const months = Math.floor(remainingDifference / (30 * 24 * 60 * 60 * 1000));
  const remainingDifferenceAfterMonths =
    remainingDifference - months * (30 * 24 * 60 * 60 * 1000);
  const weeks = Math.floor(
    remainingDifferenceAfterMonths / (7 * 24 * 60 * 60 * 1000)
  );
  const remainingDifferenceAfterWeeks =
    remainingDifferenceAfterMonths - weeks * (7 * 24 * 60 * 60 * 1000);
  const days = Math.floor(
    remainingDifferenceAfterWeeks / (24 * 60 * 60 * 1000)
  );

  // Step 5: Format the result in the desired format
  let formattedAverage = '';
  if (years > 0) {
    formattedAverage += years === 1 ? '1 year' : `${years} years`;
  }
  if (months > 0) {
    formattedAverage += years > 0 ? ', ' : '';
    formattedAverage += months === 1 ? '1 month' : `${months} months`;
  }
  if (weeks > 0) {
    formattedAverage += years > 0 || months > 0 ? ', ' : '';
    formattedAverage += weeks === 1 ? '1 week' : `${weeks} weeks`;
  }
  if (days > 0) {
    formattedAverage += years > 0 || months > 0 || weeks > 0 ? ', ' : '';
    formattedAverage += days === 1 ? '1 day' : `${days} days`;
  }

  if (!formattedAverage) {
    return '1 day';
  }

  return formattedAverage;
};

export const filterRepeatedDates = (dates) => {
  let filteredDates = [...dates];
  filteredDates = filteredDates.sort((a, b) =>
    dayjs(b.created_at).diff(dayjs(a.created_at))
  );
  const initDate = filteredDates[0];
  filteredDates = filteredDates.reduce((acc, item, index) => {
    const date = dayjs(item.label).format('YYYY/MM/DD');
    if (index > 0 && date !== dayjs(initDate.label).format('YYYY/MM/DD')) {
      acc[date] = item.value;
    }
    return acc;
  }, {});

  filteredDates = Object.entries(filteredDates).map(([label, value]) => ({
    label,
    value,
  }));
  filteredDates.unshift(initDate);
  return filteredDates;
};

export const compareDatesAfter = (date1, date2) => {
  return dayjs(date1).isAfter(date2);
};

export const formatTimeDifferenceFromNow = (date) => {
  const formattedDifference = dayjs().to(dayjs(date));
  return formattedDifference;
};

export const formatDateYearMonthDay = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};
