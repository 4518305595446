const FONT_PRIMARY = 'DM Sans';
const typography = {
  light: {
    fontFamily: FONT_PRIMARY,
    h1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '88px',
      lineHeight: '115px',
      color: '#1e0532',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '42px',
      lineHeight: '62px',
      color: '#1e0532',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '38px',
      lineHeight: '49px',
      color: '#7A818C',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '26px',
      lineHeight: '34px',
      color: '#1e0532',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '31px',
      color: '#1e0532',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#1e0532',
    },
    h7: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#1e0532',
    },
    b1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#55585C',
    },
    b2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#B0B6BE',
    },
    b3: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#7A818C',
    },
    b4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#B0B6BE',
    },
    cta1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.1em',
      color: '#B0B6BE',
    },
    cta2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#1e0532',
    },
    textLink1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#7A818C',
    },
  },
  dark: {
    fontFamily: FONT_PRIMARY,
    h1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '88px',
      lineHeight: '115px',
      color: '#FFFFFF',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '42px',
      lineHeight: '62px',
      color: '#FFFFFF',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '38px',
      lineHeight: '49px',
      color: '#FFFFFF',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '26px',
      lineHeight: '34px',
      color: '#FFFFFF',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '31px',
      color: '#FFFFFF',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#FFFFFF',
    },
    h7: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#FFFFFF',
    },
    b1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#837C95',
    },
    b2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#FFFFFF',
    },
    b3: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#FFFFFF',
    },
    b4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#B0B6BE',
    },
    cta1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.1em',
      color: '#B0B6BE',
    },
    cta2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#FFFFFF',
    },
    textLink1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#FFFFFF',
    },
  },
  gold: {
    fontFamily: FONT_PRIMARY,
    h1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '88px',
      lineHeight: '115px',
      color: '#FFFFFF',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '42px',
      lineHeight: '62px',
      color: '#FFFFFF',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '38px',
      lineHeight: '49px',
      color: '#FFFFFF',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '26px',
      lineHeight: '34px',
      color: '#FFFFFF',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '31px',
      color: '#FFFFFF',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#FFFFFF',
    },
    h7: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#FFFFFF',
    },
    b1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#837C95',
    },
    b2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#FFFFFF',
    },
    b3: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#FFFFFF',
    },
    b4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#B0B6BE',
    },
    cta1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.1em',
      color: '#B0B6BE',
    },
    cta2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#FFFFFF',
    },
    textLink1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.1em',
      color: '#FFFFFF',
    },
  },
};
export default typography;
