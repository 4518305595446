// @import Dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';

// @import Services
import {
  updateAutopilotJobSettingsAPI,
  getAutopilotJobSettingsAPI,
  getAutopilotJobsAPI,
  updateAutopilotJobAPI,
  getAutopilotJobCsvAPI,
  updateAutopilotRowAPI,
  getAutopilotJobAPI,
  getAutopilotBatchRowsAPI,
  pushAutopilotJobToCmsAPI,
  rerunAutopilotBatchRowAPI,
  reoptimizeAutopilotBatchRowAPI,
  testGoogleIndexingAPI,
  createManualJobAPI,
  getRerunAutopilotBatchRowAPI,
  getReoptimizedAutopilotBatchRowAPI,
  autopilotRescoreAPI,
  getAautopilotRescoreAPI,
  getOnboardingJobDataAPI,
  postOnboardingReRunAPI,
  getOnboardingReRunAPI,
  autopilotSampleRerunAPI,
} from '@api/autopilot';
import { getDashboardData, setShopDataAdded } from '@redux/slices/dashboard';

// @import Reducers
import {
  decreaseOptimizeAssetsCounter,
  setNoContentPushed,
  setOptimizeAssetsCounter,
} from '@redux/slices/navigation';

// @import Utils
import { getFireworks } from '@utils/confetti';
import { handleRowLimitException, pollRequest } from '@utils/slices';
import { generateContentStructureText } from '@shared/OptimizeSettings/Form/Utils';
import { camalize } from '@utils/validations';

const initialLoadings = {
  loadingPushToCms: false,
  loadingAutopilotJobSettings: false,
  loadingAutopilotJobs: false,
  loadingEditAutopilotRow: false,
  loadingRerunAutopilotBatchRow: false,
  loadingReoptimizeAutopilotBatchRow: false,
  loadingCreateManualJob: false,
  loadingAutopilotJobTestGoogleIndexing: false,
  loadingAutopilotRescore: false,
  loadingOnboardingJob: false,
  loadingReRunOnboarding: false,
  loadingSampleReRun: false,
};

export const initialState = {
  loadings: initialLoadings,
  autopilotJobs: [],
  autopilotJobsSettings: [],
  autopilotJobsMetadata: {},
  autopilotSelection: {
    selectedRows: [],
    approvedRows: [],
    allAssetsIds: [],
    selectAllApprovedRows: false,
    selectAllRows: false,
    selectAllAssets: false,
  },
  autopilotDetailScreen: {
    loading: false,
    table: {
      loading: false,
      rowsRender: 10,
      updatedRow: null,
      updatedProductRow: null,
      updatingRowId: null,
      updatingProductRowId: null,
      updatingRescoreNeeded: false,
      showUniqueId: false,
      showTextAdded: false,
      showTextRemoved: false,
      showApprovedAssets: false,
      renderHtmlContent: true,
      pagination: {
        currentPage: 0,
      },
    },
  },
  contentStructureData: { tabs: {} },
  editingAutopilotResultId: null,
  autopilotJobSettings: null,
  autopilotJobSettingsCard: null,
  onboarding: {
    jobData: null,
    ruleDifference: null,
  },
};
// ------------------THUNKS----------------

export const createManualJob = createAsyncThunk(
  'autopilot/createManualJob',
  async (body, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await createManualJobAPI(body);

    if (isSuccessful) {
      return { data: { ...res }, statusKey };
    }
    handleRowLimitException({ statusKey, dispatch: thunkAPI.dispatch });
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const getAutopilotJob = createAsyncThunk(
  'autopilot/getAutopilotJob',
  async (id, thunkAPI) => {
    const { data: res, isSuccessful, statusKey } = await getAutopilotJobAPI(id);
    if (isSuccessful) {
      return res;
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const updateAutopilotJob = createAsyncThunk(
  'autopilot/updateAutopilotJob',
  async ({ id, body }, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await updateAutopilotJobAPI({ id, body });
    if (isSuccessful) {
      if (body?.marked_as_trash) {
        const job = thunkAPI
          .getState()
          ?.autopilot?.autopilotJobs.find((_j) => _j?.id === id);
        thunkAPI.dispatch(decreaseOptimizeAssetsCounter(job));
      }
      return { data: { id, ...body }, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const getAutopilotBatchRows = createAsyncThunk(
  'autopilot/getAutopilotBatchRows',
  async (data, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await getAutopilotBatchRowsAPI(data);
    if (isSuccessful) {
      return { rowsResponse: res, page: data?.page || 1 };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const updateAutopilotRow = createAsyncThunk(
  'autopilot/updateAutopilotRow',
  async ({ body, rowId, extraParams }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateAutopilotRowAPI({
      body,
      rowId,
    });
    if (isSuccessful) return { ...body, statusKey, rowId, ...extraParams };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getAutopilotJobSettings = createAsyncThunk(
  'autopilot/getAutopilotJobSettings',
  async ({ id }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getAutopilotJobSettingsAPI(
      id
    );
    if (isSuccessful) {
      thunkAPI.dispatch(setShopDataAdded(data));
      return data;
    }

    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateAutopilotJobSettings = createAsyncThunk(
  'autopilot/updateAutopilotJobSettings',
  async ({ body, autopilotId, showSnack = true }, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await updateAutopilotJobSettingsAPI({
        body,
        autopilotId,
      });
    if (isSuccessful) return { ...data, statusKey, showSnack };
    return thunkAPI.rejectWithValue({ ...data, statusKey, showSnack });
  }
);

export const getAutopilotJobs = createAsyncThunk(
  'autopilot/getAutopilotJobs',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getAutopilotJobsAPI(params);
    if (isSuccessful) {
      const isGSC = thunkAPI
        .getState()
        ?.autopilot?.autopilotJobsSettings?.some((_j) => _j?.integrated_google);
      thunkAPI.dispatch(setOptimizeAssetsCounter({ jobs: data, isGSC }));
      return data;
    }
    return thunkAPI.rejectWithValue({ data, statusKey });
  }
);

export const getListAutopilotJobSettings = createAsyncThunk(
  'autopilot/getListAutopilotJobSettings',
  async (params = {}, thunkAPI) => {
    const data = await thunkAPI.dispatch(getDashboardData(params)).unwrap();
    if (data?.isSuccessful) return data;
    return thunkAPI.rejectWithValue({ data });
  }
);

export const rerunAutopilotBatchRow = createAsyncThunk(
  'autopilot/rerunAutopilotBatchRow',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await rerunAutopilotBatchRowAPI(
      params
    );
    if (!isSuccessful) {
      handleRowLimitException({
        statusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({ data, statusKey });
    }
    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data?.id },
      request: getRerunAutopilotBatchRowAPI,
    });
    if (!isPollSuccessful) {
      handleRowLimitException({
        statusKey: pollStatusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({
        data: pollData,
        statusKey: pollStatusKey,
      });
    }
    return {
      data: pollData,
      rowId: params?.rowId,
      productId: params?.productId,
      statusKey: pollStatusKey,
    };
  }
);

export const pushAutopilotJobToCms = createAsyncThunk(
  'autopilot/pushAutopilotJobToCms',
  async (body, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await pushAutopilotJobToCmsAPI(body);
    if (isSuccessful) {
      const state = thunkAPI.getState();
      const job = state?.autopilot?.autopilotJobs.find(
        (_j) => _j?.id === body.workflows_id
      );
      const shopId = state?.navigation?.tracker?.shopId;
      thunkAPI.dispatch(decreaseOptimizeAssetsCounter(job));
      if (shopId === body?.publicShop) {
        thunkAPI.dispatch(setNoContentPushed(false));
        getFireworks(7);
      }
      return { ...res, statusKey, job };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const getAutopilotJobCsv = createAsyncThunk(
  'autopilot/getAutopilotJobCsv',
  async (autopilotJobId, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await getAutopilotJobCsvAPI(autopilotJobId);
    if (isSuccessful) {
      return res;
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const autopilotRescore = createAsyncThunk(
  'autopilot/autopilotRescore',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await autopilotRescoreAPI(body);
    if (!isSuccessful) {
      handleRowLimitException({
        statusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({ data, statusKey });
    }
    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data?.id },
      request: getAautopilotRescoreAPI,
    });
    if (!isPollSuccessful) {
      handleRowLimitException({
        statusKey: pollStatusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({
        data: pollData,
        statusKey: pollStatusKey,
      });
    }
    return { ...pollData, statusKey: pollStatusKey };
  }
);

export const reoptimizeAutopilotBatchRow = createAsyncThunk(
  'autopilot/reoptimizeAutopilotBatchRow',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await reoptimizeAutopilotBatchRowAPI(params);
    if (!isSuccessful) {
      handleRowLimitException({ statusKey, dispatch: thunkAPI.dispatch });
      return thunkAPI.rejectWithValue({ ...data, statusKey });
    }
    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data?.id },
      request: getReoptimizedAutopilotBatchRowAPI,
    });
    if (!isPollSuccessful) {
      handleRowLimitException({
        statusKey: pollStatusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({
        data: pollData,
        statusKey: pollStatusKey,
      });
    }
    return { ...pollData, statusKey: pollStatusKey };
  }
);

export const testGoogleIndexing = createAsyncThunk(
  'autopilot/testGoogleIndexing',
  async ({ autopilotId }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await testGoogleIndexingAPI({
      autopilotId,
    });
    if (isSuccessful) {
      return { ...data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey, isSuccessful });
  }
);

export const getOnboardingJobData = createAsyncThunk(
  'autopilot/getOnboardingJobData',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getOnboardingJobDataAPI(
      body
    );
    if (isSuccessful) return { ...data };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const rerunOnboarding = createAsyncThunk(
  'autopilot/rerunOnboarding',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await postOnboardingReRunAPI(
      params
    );
    if (!isSuccessful) {
      return thunkAPI.rejectWithValue({ ...data, statusKey });
    }
    const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
      params: { id: data?.id },
      request: getOnboardingReRunAPI,
    });
    if (!isPollSuccessful) {
      return thunkAPI.rejectWithValue({
        data: pollData,
        statusKey: pollStatusKey,
      });
    }
    return { ...pollData, statusKey: pollStatusKey };
  }
);

export const autopilotSampleRerun = createAsyncThunk(
  'autopilot/autopilotSampleRerun',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await autopilotSampleRerunAPI(
      body
    );
    if (!isSuccessful) {
      handleRowLimitException({
        statusKey,
        dispatch: thunkAPI.dispatch,
      });
      return thunkAPI.rejectWithValue({ data, statusKey });
    }
    if (data?.cache_id) {
      const { pollData, pollStatusKey, isPollSuccessful } = await pollRequest({
        params: { id: data?.cache_id },
        request: getRerunAutopilotBatchRowAPI,
      });
      if (!isPollSuccessful) {
        handleRowLimitException({
          statusKey: pollStatusKey,
          dispatch: thunkAPI.dispatch,
        });
        return thunkAPI.rejectWithValue({
          data: pollData,
          statusKey: pollStatusKey,
        });
      }
      return {
        data: pollData,
        rowId: body.id,
        productId: body.productId,
        jobId: body.jobId,
        statusKey: pollStatusKey,
        thumbsUp: false,
      };
    }
    return {
      data,
      rowId: body.id,
      productId: body.productId,
      jobId: body.jobId,
      statusKey,
      thumbsUp: true,
    };
  }
);

export const sharedExtraReducers = (builder) => {
  builder
    .addCase(createManualJob.pending, (state) => {
      state.loadings = { ...state.loadings, loadingCreateManualJob: true };
    })
    .addCase(createManualJob.fulfilled, (state, action) => {
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      const oldJobs = [...state.autopilotJobs];
      oldJobs.unshift(action?.payload?.data);
      state.autopilotJobs = oldJobs;
      state.loadings = { ...state.loadings, loadingCreateManualJob: false };
    })
    .addCase(createManualJob.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.loadings = { ...state.loadings, loadingCreateManualJob: false };
    })
    .addCase(getAutopilotJobSettings.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAutopilotJobSettings: true };
    })
    .addCase(getAutopilotJobSettings.fulfilled, (state, action) => {
      state.autopilotJobSettings = action?.payload;
      state.autopilotJobsMetadata[action.payload?.id] = action.payload;
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobSettings: false,
      };
      action.payload.automated_fields.forEach((af) => {
        const selectedContentStructureValue = generateContentStructureText({
          html: af.html,
          htmlOptional: af.html_optional,
        });
        if (selectedContentStructureValue.length > 1) {
          const foundSelectedValueIndex =
            selectedContentStructureValue.findIndex((item) => !!item.selected);
          state.contentStructureData.tabs[camalize(af.display_name)] =
            foundSelectedValueIndex;
        }
      });
    })
    .addCase(getAutopilotJobSettings.rejected, (state) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobSettings: false,
      };
    })
    .addCase(updateAutopilotJobSettings.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAutopilotJobSettings: true };
    })
    .addCase(updateAutopilotJobSettings.fulfilled, (state, action) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobSettings: false,
      };
      const currentAutopilotJobSettings = [...state.autopilotJobsSettings];
      state.autopilotJobSettings = action?.payload;
      const newData = action.payload;
      const foundAutopilotJobSettingIndex =
        currentAutopilotJobSettings.findIndex((item) => item.id === newData.id);
      if (foundAutopilotJobSettingIndex !== -1) {
        state.autopilotJobsSettings[foundAutopilotJobSettingIndex] = {
          ...state.autopilotJobsSettings[foundAutopilotJobSettingIndex],
          headless: false,
          ...newData,
        };

        if (newData?.showSnack) {
          nomenclatureSnack({
            type: 'success',
            message: action?.payload?.statusKey,
          });
        }
      } else {
        state.autopilotJobsSettings.push({ ...newData });
      }
      state.autopilotJobsMetadata[newData.id] = {
        ...state.autopilotJobsMetadata[newData.id],
        headless: false,
        ...newData,
      };
    })
    .addCase(updateAutopilotJobSettings.rejected, (state, action) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobSettings: false,
      };
      if (action?.payload?.showSnack) {
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      }
    })
    .addCase(getListAutopilotJobSettings.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAutopilotJobSettings: true };
    })
    .addCase(getListAutopilotJobSettings.fulfilled, (state, action) => {
      const { shops, page } = action.payload;
      const oldShops = page >= 2 ? [...state.autopilotJobsSettings] : [];
      const newShopsList = [...oldShops, ...shops].map((_s) => {
        return { ..._s, value: _s?.id, label: _s?.public_shop_url };
      });

      state.autopilotJobsSettings = newShopsList;
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobSettings: false,
      };
    })
    .addCase(getListAutopilotJobSettings.rejected, (state) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobSettings: false,
      };
    })
    .addCase(getAutopilotJobs.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAutopilotJobs: true };
    })
    .addCase(getAutopilotJobs.fulfilled, (state, action) => {
      state.autopilotJobs = action.payload;
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobs: false,
      };
    })
    .addCase(getAutopilotJobs.rejected, (state) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobs: false,
      };
    })
    .addCase(getAutopilotJob.pending, (state) => {
      state.autopilotDetailScreen.loading = true;
    })
    .addCase(getAutopilotJob.fulfilled, (state, action) => {
      state.autopilotJobs = [{ ...action.payload }];
      state.autopilotDetailScreen.loading = false;
    })
    .addCase(getAutopilotJob.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.autopilotDetailScreen.loading = false;
    })
    .addCase(updateAutopilotJob.pending, (state) => {
      state.autopilotDetailScreen.loading = true;
    })
    .addCase(updateAutopilotJob.fulfilled, (state, action) => {
      const updatedAutopilotJob = action?.payload?.data;
      const currentAutopilotJobs = [...state.autopilotJobs];
      const foundAutopilotJobIndex = currentAutopilotJobs.findIndex(
        (item) => item.id === updatedAutopilotJob.id
      );
      if (foundAutopilotJobIndex !== -1) {
        state.autopilotJobs[foundAutopilotJobIndex] = {
          ...state.autopilotJobs[foundAutopilotJobIndex],
          ...updatedAutopilotJob,
        };
      }
      state.autopilotDetailScreen.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateAutopilotJob.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.autopilotDetailScreen.loading = false;
    })
    .addCase(getAutopilotBatchRows.pending, (state) => {
      state.autopilotDetailScreen.table.loading = true;
    })
    .addCase(getAutopilotBatchRows.fulfilled, (state, action) => {
      const { rowsResponse: autopilotBatchRows, page } = action.payload;
      const approvedIds = [];
      const allIds = [];
      autopilotBatchRows.forEach((item) =>
        item.rows.forEach((row) => {
          if (row.approved) approvedIds.push(row.id);
          allIds.push(row.id);
        })
      );
      if (approvedIds.length === allIds.length)
        state.autopilotSelection.selectAllAssets = true;
      state.autopilotSelection.approvedRows = approvedIds;
      state.autopilotSelection.allAssetsIds = allIds;
      state.autopilotDetailScreen.table.loading = false;
      state.autopilotDetailScreen.table.pagination.currentPage = page;
    })
    .addCase(getAutopilotBatchRows.rejected, (state) => {
      state.autopilotDetailScreen.table.loading = false;
    })
    .addCase(rerunAutopilotBatchRow.pending, (state, action) => {
      state.autopilotDetailScreen.table.updatingRescoreNeeded = true;
      state.loadings.loadingRerunAutopilotBatchRow = true;
      state.autopilotDetailScreen.table.updatingRowId =
        action?.meta?.arg?.rowId;
    })
    .addCase(rerunAutopilotBatchRow.fulfilled, (state, action) => {
      state.loadings.loadingRerunAutopilotBatchRow = false;
      const { data, rowId, productId } = action.payload;
      state.autopilotDetailScreen.table.updatedRow = {
        id: rowId,
        productId,
        newValues: {
          output: data?.edited_text ?? data?.output,
          id: data?.id ?? undefined,
        },
      };
      state.autopilotDetailScreen.table.updatingRowId = null;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(rerunAutopilotBatchRow.rejected, (state) => {
      state.loadings.loadingRerunAutopilotBatchRow = false;
      state.autopilotDetailScreen.table.updatingRowId = null;
    })
    .addCase(pushAutopilotJobToCms.pending, (state) => {
      state.loadings = { ...state.loadings, loadingPushToCms: true };
    })
    .addCase(pushAutopilotJobToCms.fulfilled, (state, action) => {
      const currentAutopilotJobs = [...state.autopilotJobs];
      const foundAutopilotJobIndex = currentAutopilotJobs.findIndex(
        (item) => item.id === action?.payload?.job?.id
      );
      if (foundAutopilotJobIndex !== -1) {
        state.autopilotJobs[foundAutopilotJobIndex] = {
          ...state.autopilotJobs[foundAutopilotJobIndex],
          state: 'sent',
        };
      }
      state.autopilotSelection.selectedRows = [];
      state.autopilotSelection.selectAllRows = false;
      state.loadings = { ...state.loadings, loadingPushToCms: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(pushAutopilotJobToCms.rejected, (state, action) => {
      state.autopilotSelection.selectedRows = [];
      state.autopilotSelection.selectAllRows = false;
      state.loadings = { ...state.loadings, loadingPushToCms: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateAutopilotRow.pending, (state) => {
      state.autopilotDetailScreen.table.updatingRescoreNeeded = true;
      state.loadings = { ...state.loadings, loadingEditAutopilotRow: true };
    })
    .addCase(updateAutopilotRow.fulfilled, (state, action) => {
      state.editingResultId = null;
      state.loadings = { ...state.loadings, loadingEditAutopilotRow: false };
      state.autopilotDetailScreen.table.updatedRow = {
        ...action?.payload,
        id: action?.payload?.rowId,
        newValues: {
          output: action?.payload?.edited_text ?? action?.payload?.output,
          approved: action?.payload?.approved ?? undefined,
        },
      };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateAutopilotRow.rejected, (state, action) => {
      state.editingResultId = null;
      state.loadings = { ...state.loadings, loadingEditAutopilotRow: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getAutopilotJobCsv.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAutopilotJobs: true };
    })
    .addCase(getAutopilotJobCsv.fulfilled, (state) => {
      state.loadings = { ...state.loadings, loadingAutopilotJobs: false };
    })
    .addCase(getAutopilotJobCsv.rejected, (state, action) => {
      state.loadings = { ...state.loadings, loadingAutopilotJobs: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(reoptimizeAutopilotBatchRow.pending, (state, action) => {
      state.autopilotDetailScreen.table.updatingRescoreNeeded = true;
      state.loadings.loadingReoptimizeAutopilotBatchRow = true;
      state.autopilotDetailScreen.table.updatingProductRowId =
        action?.meta?.arg?.rowId;
    })
    .addCase(reoptimizeAutopilotBatchRow.fulfilled, (state, action) => {
      state.loadings.loadingReoptimizeAutopilotBatchRow = false;
      const data = action.payload;
      state.autopilotDetailScreen.table.updatedProductRow = {
        ...data,
      };
      state.autopilotDetailScreen.table.updatingProductRowId = null;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(reoptimizeAutopilotBatchRow.rejected, (state) => {
      state.loadings.loadingReoptimizeAutopilotBatchRow = false;
      state.autopilotDetailScreen.table.updatingProductRowId = null;
    })

    .addCase(testGoogleIndexing.pending, (state) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobTestGoogleIndexing: true,
      };
    })
    .addCase(testGoogleIndexing.fulfilled, (state, action) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobTestGoogleIndexing: false,
      };
      state.autopilotJobSettings.indexing_setup = true;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(testGoogleIndexing.rejected, (state, action) => {
      state.loadings = {
        ...state.loadings,
        loadingAutopilotJobTestGoogleIndexing: false,
      };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(autopilotRescore.pending, (state, action) => {
      state.autopilotDetailScreen.table.updatingRescoreNeeded = false;
      state.autopilotDetailScreen.table.updatingProductRowId =
        action?.meta?.arg?.id;
      state.loadings.loadingAutopilotRescore = true;
    })
    .addCase(autopilotRescore.fulfilled, (state, action) => {
      const data = action.payload;
      state.autopilotDetailScreen.table.updatedProductRow = {
        ...data,
      };
      state.loadings.loadingAutopilotRescore = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(autopilotRescore.rejected, (state) => {
      state.autopilotDetailScreen.table.updatingProductRowId = null;
      state.loadings.loadingAutopilotRescore = false;
    })
    .addCase(getOnboardingJobData.pending, (state) => {
      state.loadings.loadingOnboardingJob = true;
    })
    .addCase(getOnboardingJobData.fulfilled, (state, action) => {
      state.loadings.loadingOnboardingJob = false;
      state.onboarding.jobData = action.payload;
    })
    .addCase(getOnboardingJobData.rejected, (state) => {
      state.loadings.loadingOnboardingJob = false;
      state.onboarding.jobData = null;
    })
    .addCase(rerunOnboarding.pending, (state) => {
      state.loadings.loadingReRunOnboarding = true;
    })
    .addCase(rerunOnboarding.fulfilled, (state, action) => {
      state.loadings.loadingReRunOnboarding = false;
      state.onboarding.ruleDifference = action.payload?.difference;
    })
    .addCase(rerunOnboarding.rejected, (state) => {
      state.loadings.loadingReRunOnboarding = false;
      state.onboarding.ruleDifference = null;
    })
    .addCase(autopilotSampleRerun.pending, (state, action) => {
      state.loadings = {
        ...state.loadings,
        loadingSampleReRun: true,
        loadingEditAutopilotRow: Boolean(action?.meta?.arg?.feedback),
        loadingRerunAutopilotBatchRow: Boolean(action?.meta?.arg?.feedback),
      };
      state.autopilotDetailScreen.table.updatingRowId = action?.meta?.arg?.id;
    })
    .addCase(autopilotSampleRerun.fulfilled, (state, action) => {
      state.loadings.loadingSampleReRun = false;
      state.loadings = {
        ...state.loadings,
        loadingSampleReRun: false,
        loadingEditAutopilotRow: false,
        loadingRerunAutopilotBatchRow: false,
      };
      const { data, rowId, productId, thumbsUp, jobId } = action.payload;
      state.autopilotDetailScreen.table.updatedRow = {
        id: rowId,
        productId,
        newValues: {
          thumbs_up: thumbsUp,
          output: data?.edited_text ?? data?.output ?? undefined,
          id: data?.id ?? undefined,
        },
      };
      const updateJobIndex = state.autopilotJobs.findIndex(
        (_j) => _j.id === jobId
      );
      if (updateJobIndex !== -1) {
        state.autopilotJobs[updateJobIndex].fully_up = data?.fully_up ?? false;
      }
      state.autopilotDetailScreen.table.updatingRowId = null;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(autopilotSampleRerun.rejected, (state) => {
      state.loadings = {
        ...state.loadings,
        loadingSampleReRun: false,
        loadingEditAutopilotRow: false,
        loadingRerunAutopilotBatchRow: false,
      };
      state.autopilotDetailScreen.table.updatingRowId = null;
    });
};

export const autopilotSlice = createSlice({
  name: 'autopilot',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setAutopilotDetailScreenTablePage: (state, action) => {
      state.autopilotDetailScreen.table.pagination.currentPage = action.payload;
    },
    setSelectedAutopilotRows: (state, action) => {
      state.autopilotSelection.selectedRows = action.payload;
    },
    setSelectAutopilotAllRows: (state, action) => {
      state.autopilotSelection.selectAllRows = action.payload;
    },
    setSelectAutopilotAllAssets: (state, action) => {
      state.autopilotSelection.selectAllAssets = action.payload;
    },
    setSelectedApprovedAutopilotRows: (state, action) => {
      state.autopilotSelection.approvedRows = action.payload;
    },
    setSelectApprovedAutopilotAllRows: (state, action) => {
      state.autopilotSelection.selectAllApprovedRows = action.payload;
    },
    setEditingAutopilotResultId: (state, action) => {
      state.editingAutopilotResultId = action.payload;
    },
    resetAutopilotJobSettings: (state) => {
      state.autopilotJobSettings = null;
    },
    setAutopilotRowRenders: (state, action) => {
      state.autopilotDetailScreen.table.rowsRender = action.payload;
    },
    setAutopilotJobSettingsCard: (state, action) => {
      state.autopilotJobSettingsCard = action.payload;
    },
    setAutopilotJobSettingsCardById: (state, action) => {
      const foundJobSettings = state.autopilotJobsSettings?.find(
        (_j) => _j?.id === parseInt(action.payload)
      );
      state.autopilotJobSettingsCard = foundJobSettings;
    },
    resetAutopilotUpdatedRow: (state) => {
      state.autopilotDetailScreen.table.updatedRow = null;
    },
    resetAutopilotUpdatedProductRow: (state) => {
      state.autopilotDetailScreen.table.updatedProductRow = null;
    },
    setShowAutopilotUniqueId: (state, action) => {
      state.autopilotDetailScreen.table.showUniqueId = action?.payload;
    },
    setShowAutopilotApprovedAssets: (state, action) => {
      state.autopilotDetailScreen.table.showApprovedAssets = action?.payload;
    },
    setShowAutopilotTextRemoved: (state, action) => {
      state.autopilotDetailScreen.table.showTextRemoved = action?.payload;
    },
    setShowAutopilotTextAdded: (state, action) => {
      state.autopilotDetailScreen.table.showTextAdded = action?.payload;
    },
    setRenderAutopilotHtmlContent: (state, action) => {
      state.autopilotDetailScreen.table.renderHtmlContent = action?.payload;
    },
    setContentStructureTab: (state, action) => {
      const { type, tab } = action.payload;
      state.contentStructureData.tabs[type] = tab;
    },
    setOnboardingJobData: (state, action) => {
      state.onboarding.jobData = action.payload;
    },
    setRuleDifference: (state, action) => {
      state.onboarding.ruleDifference = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  setAutopilotDetailScreenTablePage,
  setSelectedAutopilotRows,
  setSelectAutopilotAllRows,
  setSelectAutopilotAllAssets,
  setSelectedApprovedAutopilotRows,
  setSelectApprovedAutopilotAllRows,
  setEditingAutopilotResultId,
  resetAutopilotJobSettings,
  setAutopilotRowRenders,
  setAutopilotJobSettingsCard,
  setAutopilotJobSettingsCardById,
  resetAutopilotUpdatedRow,
  resetAutopilotUpdatedProductRow,
  setShowAutopilotApprovedAssets,
  setShowAutopilotTextAdded,
  setShowAutopilotTextRemoved,
  setShowAutopilotUniqueId,
  setRenderAutopilotHtmlContent,
  setContentStructureTab,
  setOnboardingJobData,
  setRuleDifference,
} = autopilotSlice.actions;
export default autopilotSlice.reducer;

// ------------------SELECTORS-------------

export const selectAutopilotLoadings = (state) => state.autopilot.loadings;

export const selectAutopilotDetailScreenLoading = (state) =>
  state.autopilot.autopilotDetailScreen.loading;

export const selectAutopilotDetailScreenTableData = (state) =>
  state.autopilot.autopilotDetailScreen.table;

export const selectAutopilotDetailScreenLoadingTable = (state) =>
  state.autopilot.autopilotDetailScreen.table.loading;

export const selectAutopilotDetailScreenTablePagination = (state) =>
  state.autopilot.autopilotDetailScreen.table.pagination;

export const selectAutopilotDetailScreenTablePage = (state) =>
  state.autopilot.autopilotDetailScreen.table.pagination.currentPage;

export const selectAutopilotDetailScreenTableUpdatedRow = (state) =>
  state.autopilot.autopilotDetailScreen.table.updatedRow;

export const selectAutopilotDetailScreenTableUpdatedProductRow = (state) =>
  state.autopilot.autopilotDetailScreen.table.updatedProductRow;

export const selectAutopilotDetailScreenTableUpdatingProductRowId = (state) =>
  state.autopilot.autopilotDetailScreen.table.updatingProductRowId;

export const selectAutopilotDetailScreenTableUpdatingRowId = (state) =>
  state.autopilot.autopilotDetailScreen.table.updatingRowId;

export const selectAutopilotDetailScreenTableSelectedRows = (state) =>
  state.autopilot.autopilotSelection.selectedRows;

export const selectAutopilotTableRowsRender = (state) =>
  state.autopilot.autopilotDetailScreen.table.rowsRender;

export const selectAutopilotDetailScreenTableSelectAllRows = (state) =>
  state.autopilot.autopilotSelection.selectAllRows;

export const selectAutopilotDetailScreenTableSelectAllAssets = (state) =>
  state.autopilot.autopilotSelection.selectAllAssets;

export const selectAutopilotDetailScreenTableSelectApprovedRows = (state) =>
  state.autopilot.autopilotSelection.approvedRows;

export const selectAutopilotDetailScreenTableSelectAssetsIds = (state) =>
  state.autopilot.autopilotSelection.allAssetsIds;

export const selectAutopilotDetailScreenTableSelectApprovedAllRows = (state) =>
  state.autopilot.autopilotSelection.selectAllApprovedRows;

export const selectAutopilotById = (state, id) =>
  state.autopilot.autopilotJobs?.find((_w) => _w?.id === id);

export const selectIsEditingAutopilotResultId = (state) =>
  state.autopilot.editingAutopilotResultId;

export const selectAutopilotJobSettings = (state) =>
  state.autopilot.autopilotJobSettings;

export const selectAutopilotJobs = (state) => state.autopilot.autopilotJobs;

export const selectListAutopilotJobSettings = (state) =>
  state.autopilot.autopilotJobsSettings;

export const selectAutopilotJobSettingsCard = (state) =>
  state.autopilot.autopilotJobSettingsCard;

export const selectAutopilotJobMetadata = (state, jobId) =>
  state.autopilot.autopilotJobsMetadata[jobId];

export const selectContentStructureTab = (state, type) =>
  state.autopilot.contentStructureData.tabs[type];

export const selectAutopilotJobSettingsById = (state, id) =>
  state.autopilot.autopilotJobsSettings?.find((_j) => _j?.id === id);

export const selectOnboardingJobData = (state) => state.autopilot.onboarding;
