import { makeRequest } from '@api/client';

const login = async (body) => {
  return await makeRequest(`auth/login`, {
    method: 'POST',
    body: body,
  });
};

const logout = async (params) => {
  return await makeRequest(
    `auth/logout`,
    {
      method: 'POST',
    },
    params?.extraAttributes
  );
};

const register = async (body) => {
  return await makeRequest(`auth/register`, {
    method: 'POST',
    body: body,
  });
};

const verifyEmail = async () => {
  return await makeRequest(`auth/email-verification`, {
    method: 'GET',
  });
};

const confirmEmail = async (token) => {
  return await makeRequest(`auth/verify-email/${token}/`, {
    method: 'GET',
  });
};

const forgotPassword = async (body) => {
  return await makeRequest(`auth/forgot-password`, {
    method: 'POST',
    body,
  });
};

const setNewPassword = async (body, token) => {
  return await makeRequest(`auth/reset-password/${token}/`, {
    method: 'POST',
    body,
  });
};

const changePasswordRequest = async (body) => {
  return await makeRequest(`auth/change-password`, {
    method: 'POST',
    body: body,
  });
};

const google = async (body) => {
  return await makeRequest(`auth/google`, {
    method: 'POST',
    body: body,
  });
};

const validateInvitationCodeAPI = async (params) => {
  return await makeRequest(
    `invitation/${params.code}`,
    {
      method: 'GET',
    },
    params?.extraAttributes
  );
};

export {
  register,
  verifyEmail,
  confirmEmail,
  login,
  logout,
  forgotPassword,
  setNewPassword,
  changePasswordRequest,
  validateInvitationCodeAPI,
  google,
};
