function path(root, sublink) {
  return `${root}${sublink}`;
}

const root = '/app';
const authRoot = '/';

export const externalPaths = {
  ekomAutoIndexGuide: 'https://ekom.ai/auto-index',
  ekomGA4Connection: 'https://www.ekom.ai/ga4-instructions',
  magento: 'https://www.ekom.ai/magento-integration',
  termsOfService: 'https://www.ekom.ai/terms-of-service',
  shopifyGuide: 'https://ekom.ai/shopify',
};

export const guestPaths = {
  root,
  login: path(authRoot, `login`),
  loginEmbedded: path(authRoot, `login-embedded`),
  signUp: path(authRoot, `signup`),
  passwordRecovery: path(authRoot, `password-recovery`),
  signUpInvitation: path(authRoot, `sign-up/invitation`),
  joinYourTeam: path(authRoot, `join-your-team`),
  setNewPassword: path(authRoot, `set-new-password/:token`),
  verifyEmail: path(authRoot, `verify-email`),
  verifyEmailToken: path(authRoot, `verify-email/:token`),
  page404: path(root, '/test-error-component-404'),
  page500: path(root, '/test-error-component-500'),
  page1020: path(root, '/test-error-component-1020'),
};

export const authPaths = {
  root: authRoot,
  autopilot: path(root, '/autopilot'),
  settings: path(root, '/settings'),
  onboarding: path(root, '/onboarding'),
  home: path(root, '/dashboard'),
  optimizeLog: path(root, '/optimization-log'),
  signUpPlan: path(root, '/signup-plan'),
  billingRedirection: path(root, '/billing-redirection'),
  success: path(root, '/success'),
  accountSettings: path(root, '/account/settings'),
  teamMembers: path(root, '/account/team-members'),
  subscriptionPortal: '/subscription-portal',
  reusableComponents: path(root, '/test-reusable-components'),
};

export const nestedPaths = {
  autopilotDetail: path(authPaths.autopilot, '/autopilot-jobs'),
};

export const pathsByMode = Object.entries({
  dark: [guestPaths.loginEmbedded],
  light: [
    authRoot,
    guestPaths.login,
    guestPaths.passwordRecovery,
    guestPaths.joinYourTeam,
    guestPaths.setNewPassword,
    guestPaths.verifyEmail,
    guestPaths.verifyEmailToken,
  ],
}).reduce((acc, item) => {
  const newUrls = item[1].reduce((formatted, url) => {
    formatted[url] = item[0];
    return formatted;
  }, {});
  return { ...acc, ...newUrls };
}, {});

export const matchPathMode = (path) => {
  const matchingPath = Object.keys({ ...pathsByMode }).find((key) => {
    const regex = new RegExp('^' + key.replace(/:\w+/g, '[^/]+') + '$');
    return regex.test(path);
  });
  return pathsByMode[matchingPath];
};

export const renderBgImageByMode = {
  light: [authPaths.success],
};
