import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOptimizationHistoryLogAPI,
  getOptimizationLogProductDetailsAPI,
  getOtherRoundsOptionsAPI,
  getOptimizationLogStatisticsCardsAPI,
  getOptimizationLogProductMetricsAPI,
  restoreSuperRowAPI,
} from '@api/optimizationLog';
import {
  formatDateByMonthAndDay,
  formatDateYearMonthDay,
} from '@utils/date.js';
import { nomenclatureSnack } from '@utils/nomenclature';

const initialState = {
  loadingOptimizationLogStatisticsCards: false,
  optimizeLogsCards: { data: null },
  loadingOptimizationHistoryLog: false,
  loadingOptimizationHistoryLogPagination: false,
  optimizationHistoryLog: [],
  loadingProductDetails: false,
  productsDetails: {},
  loadingOtherRoundsOptions: false,
  otherRoundsOptions: {},
  selectedProduct: null,
  selectedProductToCompare: null,
  loadingRestoreSuperRow: false,
  optimizationLogPagination: {
    currentPage: 1,
    hasMoreResults: false,
  },
  otherRoundsPagination: {
    currentPage: 1,
    hasMore: false,
  },
  loadingProductMetrics: false,
  productMetrics: {},
};

export const getOptimizationLogStatisticsCards = createAsyncThunk(
  'optimizationLog/getOptimizationLogStatisticsCards',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getOptimizationLogStatisticsCardsAPI(id);
    if (isSuccessful) return { ...data, id };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const fetchOptimizationHistoryLog = createAsyncThunk(
  'optimizationLog/fetchOptimizationHistoryLog',
  async ({ shopId, filterParams }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getOptimizationHistoryLogAPI({
        id: shopId,
        params: {
          ...filterParams,
          from_date: formatDateYearMonthDay(filterParams.from_date),
          to_date: formatDateYearMonthDay(filterParams.to_date),
          [filterParams.searchBy]: filterParams.searchTerm,
        },
      });
      const data = response.data;
      if (data.length > 0 && filterParams.page === 1) {
        const firstCategory = data[0]?.categories[0];
        const firstProduct = firstCategory?.products[0];
        if (firstProduct) {
          dispatch(
            fetchProductDetails({
              shopId,
              itemInfo: firstProduct,
              filterParams,
            })
          );
          dispatch(
            fetchProductMetrics({
              shopId,
              rowId: firstProduct.autopilot_super_row_id,
            })
          );
        }
      }
      return {
        data,
        current_page: filterParams.page || 1,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductDetails = createAsyncThunk(
  'optimizationLog/fetchProductDetails',
  async ({ shopId, itemInfo, filterParams }, { rejectWithValue }) => {
    try {
      const response = await getOptimizationLogProductDetailsAPI({
        id: shopId,
        params: {
          autopilot_super_row_id: itemInfo.autopilot_super_row_id,
          ...(filterParams.isFromCompared
            ? { compare_against: filterParams.compare_against }
            : {}),
        },
      });

      return {
        ...response.data,
        isFromCompared: filterParams.isFromCompared,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductMetrics = createAsyncThunk(
  'optimizationLog/fetchProductMetrics',
  async ({ shopId, rowId }, { rejectWithValue }) => {
    try {
      const response = await getOptimizationLogProductMetricsAPI({
        shopId,
        rowId,
      });
      if (!response.isSuccessful) {
        return rejectWithValue(response.data);
      }

      return { id: rowId, ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOtherRoundsOptions = createAsyncThunk(
  'optimizationLog/fetchOtherRoundsOptions',
  async ({ shopId, rowId, filterParams }, { dispatch, rejectWithValue }) => {
    try {
      const response = await getOtherRoundsOptionsAPI({
        rowId,
        params: {
          compare_against: filterParams.compare_against,
          page: filterParams.page,
          page_size: 10,
        },
      });
      const data = response.data;
      if (data.length > 0 && filterParams.page === 1) {
        const firstRound = data[0];
        dispatch(
          fetchProductDetails({
            shopId,
            itemInfo: { autopilot_super_row_id: firstRound.row_id },
            filterParams: {
              ...filterParams,
              isFromCompared: true,
            },
          })
        );
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const restoreSuperRow = createAsyncThunk(
  'optimizationLog/restoreSuperRow',
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await restoreSuperRowAPI({ body });
      if (!response.isSuccessful) {
        return rejectWithValue(response);
      }

      return { ...response.data, statusKey: response.statusKey };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const optimizationLogSlice = createSlice({
  name: 'optimizationLog',
  initialState,
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setSelectedProductToCompare: (state, action) => {
      state.selectedProductToCompare = action.payload;
    },
    cleanOtherRoundsOptions: (state) => {
      state.otherRoundsOptions = {};
    },
    cleanAll: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptimizationHistoryLog.pending, (state, action) => {
        state.loadingOptimizationHistoryLog =
          action.meta.arg.filterParams.page <= 1;
        state.loadingOptimizationHistoryLogPagination =
          action.meta.arg.filterParams.page > 1;
      })
      .addCase(fetchOptimizationHistoryLog.fulfilled, (state, action) => {
        state.loadingOptimizationHistoryLog = false;
        state.loadingOptimizationHistoryLogPagination = false;
        let list = action.payload.data;
        if (!Array.isArray(list)) {
          list = [];
        }

        if (action.payload.current_page > 1) {
          list = [...state.optimizationHistoryLog, ...list];
        }
        state.optimizationHistoryLog = list.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.autopilot_id === value.autopilot_id)
        );
        state.optimizationLogPagination = {
          currentPage: action.payload.current_page,
          hasMoreResults: action.payload.data.length === 20,
        };
      })
      .addCase(fetchOptimizationHistoryLog.rejected, (state) => {
        state.loadingOptimizationHistoryLog = false;
        state.loadingOptimizationHistoryLogPagination = false;
      })
      .addCase(getOptimizationLogStatisticsCards.pending, (state) => {
        state.loadingOptimizationLogStatisticsCards = true;
      })
      .addCase(getOptimizationLogStatisticsCards.rejected, (state) => {
        state.loadingOptimizationLogStatisticsCards = false;
      })
      .addCase(getOptimizationLogStatisticsCards.fulfilled, (state, action) => {
        state.loadingOptimizationLogStatisticsCards = false;
        const { id, ...data } = action.payload;
        state.optimizeLogsCards = {
          ...state.optimizeLogsCards,
          data: {
            ...state.optimizeLogsCards.data,
            [id]: {
              ...state.optimizeLogsCards.data?.[id],
              cards: data,
            },
          },
        };
      })
      .addCase(fetchProductDetails.pending, (state) => {
        state.loadingProductDetails = true;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.loadingProductDetails = false;
        const { isFromCompared = false, ...data } = action.payload;
        state.productsDetails = {
          ...state.productsDetails,
          [data.id]: { ...state.productsDetails[data.id], content: data },
        };

        if (!isFromCompared) {
          state.selectedProduct = {
            autopilot_super_row_id: data.id,
            product_id: data.product,
            product_name: data.product_name,
          };
        }

        if (isFromCompared) {
          state.selectedProductToCompare = {
            ...data,
            autopilot_super_row_id: data.id,
            product_id: data.product,
            product_name: data.product_name,
          };
        }
      })
      .addCase(fetchProductDetails.rejected, (state) => {
        state.loadingProductMetrics = false;
      })
      .addCase(fetchProductMetrics.pending, (state) => {
        state.loadingProductMetrics = true;
      })
      .addCase(fetchProductMetrics.fulfilled, (state, action) => {
        state.loadingProductMetrics = false;
        const { id, ...data } = action.payload;
        state.productsDetails = {
          ...state.productsDetails,
          [id]: { ...state.productsDetails[id], metrics: data },
        };
      })
      .addCase(fetchProductMetrics.rejected, (state) => {
        state.loadingProductMetrics = false;
      })
      .addCase(fetchOtherRoundsOptions.pending, (state) => {
        state.loadingOtherRoundsOptions = true;
      })
      .addCase(fetchOtherRoundsOptions.fulfilled, (state, action) => {
        state.loadingOtherRoundsOptions = false;
        state.otherRoundsPagination = {
          currentPage: action.meta.arg.filterParams.page,
          hasMore: action.payload.length >= 1,
        };
        const newOptions = action.payload?.reduce((acc, item) => {
          acc[item.row_id] = {
            label: formatDateByMonthAndDay(item.created_at, 'MMM D, YYYY'),
            value: item.row_id,
          };
          return acc;
        }, {});

        if (action.meta.arg.filterParams.page > 1) {
          state.otherRoundsOptions = {
            ...state.otherRoundsOptions,
            ...newOptions,
          };
        } else {
          state.otherRoundsOptions = newOptions;
        }
      })
      .addCase(fetchOtherRoundsOptions.rejected, (state) => {
        state.loadingOtherRoundsOptions = false;
      })
      .addCase(restoreSuperRow.pending, (state) => {
        state.loadingRestoreSuperRow = true;
      })
      .addCase(restoreSuperRow.fulfilled, (state, action) => {
        state.loadingRestoreSuperRow = false;
        const data = action.payload;

        state.productsDetails = {
          ...state.productsDetails,
          [data.id]: { ...state.productsDetails[data.id], content: data },
        };

        nomenclatureSnack({
          type: 'success',
          message: action?.payload?.statusKey,
        });
      })
      .addCase(restoreSuperRow.rejected, (state, action) => {
        state.loadingRestoreSuperRow = false;
        nomenclatureSnack({
          type: 'error',
          message: action?.payload?.statusKey,
        });
      });
  },
});

export const selectLoadingOptimizationHistoryLog = (state) =>
  state.optimizationLog.loadingOptimizationHistoryLog;
export const selectOptimizationHistoryLog = (state) =>
  state.optimizationLog.optimizationHistoryLog;
export const selectLoadingProductDetails = (state) =>
  state.optimizationLog.loadingProductDetails;
export const selectProductsDetails = (state) =>
  state.optimizationLog.productsDetails;
export const selectLoadingOtherRoundsOptions = (state) =>
  state.optimizationLog.loadingOtherRoundsOptions;
export const selectOtherRoundsOptions = (state) =>
  state.optimizationLog.otherRoundsOptions;
export const selectSelectedProduct = (state) =>
  state.optimizationLog.selectedProduct;
export const selectOptimizationLogPagination = (state) =>
  state.optimizationLog.optimizationLogPagination;
export const selectOptimizeLogsById = (state, id) =>
  state.optimizationLog.optimizeLogsCards.data?.[id];
export const selectLoadingOptimizationLogCards = (state) =>
  state.optimizationLog.loadingOptimizationLogStatisticsCards;
export const selectSelectedProductToCompare = (state) =>
  state.optimizationLog.selectedProductToCompare;
export const selectOtherRoundsOptionsPagination = (state) =>
  state.optimizationLog.otherRoundsPagination;
export const selectProductDetailById = (state, id) =>
  state.optimizationLog.productsDetails?.[id];
export const selectLoadingRestoreSuperRow = (state) =>
  state.optimizationLog.loadingRestoreSuperRow;

export default optimizationLogSlice.reducer;
export const {
  setSelectedProduct,
  setSelectedProductToCompare,
  cleanOtherRoundsOptions,
  cleanAll,
} = optimizationLogSlice.actions;
